/**
 * Copyright (C) 2022. ProMobi Technologies Pvt. Ltd. All Rights Reserved. 
 * Author: Arnab Chakraborty (arnab@promobitech.com)
 */

 @import "tailwindcss/base";
 @import "tailwindcss/components";
 @import "tailwindcss/utilities";

 @import "./stylesheets/mixins";
 @import "./stylesheets/common";



 @layer base{
    html {
        font-family: theme('fontFamily.hind'), theme('fontFamily.sans');
        -webkit-tap-highlight-color: transparent;
        -webkit-font-feature-settings: "kern", "ss03";
        font-feature-settings: "kern", "ss03";
        -webkit-text-size-adjust: 100%;
        @apply antialiased scroll-smooth;

        body {


            h1, h2, h3 {
                font-family: theme('fontFamily.hind'), theme('fontFamily.sans');
            }

            p {
                @apply font-medium;
                &.text-lg {
                    @apply font-normal;
                }
            }
        }
    }
}

// bottom bar that contains the contact and twitter links
.bottom-bar {
    @apply absolute flex flex-row bottom-0 left-0 
            right-0 px-10 py-5 bg-gray-50 rounded-b 
            divide-x-2 divide-gray-300 text-neutral-500 
            font-medium border-t border-gray-100;
    
    .contact {
        @apply inline-block pr-4 hover:text-gray-900;
    }

    .twitter {
        @apply inline-block pl-4 hover:text-gray-900;
    }
}