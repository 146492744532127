:root {
    --color-black: #000000;
    --color-white: #ffffff;

    --color-dark-001: #1D1E35;
    --color-dark-002: #2D2D2D;
    --color-dark-005: #02051C;

    --color-gray-001: #969696;
    --color-gray-002: #E5E5E5;

    --color-blue-01: #E0F2FF;
    --color-blue-001: #39BCDC;
    --color-blue-002: #1C5BFF;
    --color-blue-003: #3505AD;
    --color-blue-004: #1B6FAC;
    --color-blue-005: #0066DD;

    --color-pale-blue: #F1F7FF;

    --c-amber-001: #DCB839;

    --color-cyan-01: #F2FAFF;
    --color-cyan-001: #9DF3FF;
    --color-cyan-002: #5BE5FF;

    --color-neon-green: #9FFF9D;
    --color-neon-green-001: #D5FFD5;

    --color-yellow-002: #FCFF6C;
    --color-yellow-003: #FCFF6C;

    --color-green-001: #D3FFEF;
    --color-green-002: #9FFF9D;
    --color-green-003: #04B680;
    --color-green-005: #17804D;

    --color-violet-001: #F8E5FF;
    --color-violet-002: #EEF5FF;
    --color-violet-004: #9149AC;
    --color-violet-005: #6B38FF;
    --color-violet-006: #6E3BC8;

    --color-brown-001: #FFF5D3;
    --color-brown-005: #725B09;

    --color-pink-001: #FF9DDE;
    --color-pink-002: #FFF2F2;
    --color-pink-003: #E1C2D5;
    --color-pink-004: #FC60FF;
    --color-pink-005: #FA5061;

    --color-emerald-001: #F2FFF2;
    --color-emerald-003: #D4FFF5;

    --color-orange-005: #FFA337;

}

.lazy {
    @apply transition duration-500 ease-out max-w-full opacity-20;
}

.lazy.loaded {
    @apply opacity-100;
}

form .form-control {
    @apply mb-5;
    input, textarea {
        @apply border border-frame rounded-lg block w-full p-2.5;
    }

    // On Error condition highlight the element
    &.has-error {
        textarea {
            @apply invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500;
        }
    }

    label[data-req]::after {
        content: attr(data-req);
        display: inline-block;
        text-align: right;
        opacity: .75;
        width: 1rem;
        color: red;
    }
}

// Job element
.job {

    .header {
        @apply flex flex-col md:flex-row space-y-2 md:space-y-0 pb-2 justify-between;

        h3 {
            @apply text-slate-700;
        }
    }

    .job-props {
        @apply mt-3 border-t border-gray-300 pt-3;
    }

    .prop {
        @apply content-center;

        h6 {
            @apply font-semibold antialiased text-2xs tracking-wide text-slate-700 pr-4 w-28 md:w-24;
        }

        p {
            @apply font-normal text-sm text-slate-700;
        }
    }
}

// View details or Apply buttons
.btn {
    &.btn-details {
        @apply text-center inline-block rounded ring-1 ring-slate-900/20
             border-gray-300 px-3 py-1.5 font-medium hover:ring-slate-900/40
             text-slate-600 hover:text-slate-800;
    }

    &.btn-submit {
        @apply bg-blue-002 py-2 px-8
                text-white rounded-md
                disabled:bg-slate-200
                disabled:text-slate-500;
    }
}

.filter-box {
    .filter-label {
        @apply font-medium text-sm tracking-wide text-slate-600;
    }

    .filter {
        @apply border-slate-400 rounded-md text-slate-600 font-medium text-sm py-2;
    }
}

.el-fade-in {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);

    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    &.in {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.modal {
    display: none;
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    overflow-y: auto;
    overflow-x: hidden;
    outline: 0;
    z-index: 1060;
    .modal-dialog {
        position: relative;
        width: calc(100% - 15px);
        pointer-events: none;
        transition: transform .3s ease-out;
        transform: translate(0, -50px);
        max-width: 1060px;
        margin: 1.75rem auto;
    }
    .modal-content {
        position: relative;
        background-color: #ffffff;
        width: 100%;
        pointer-events: all;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: .3rem;
        outline: 0;
        button#btn-close {
            position: absolute;
            width: 20px;
            height: 20px;
            right: -35px;
            top: 0;
        }
        @media (max-width: 1180px) {
            button#btn-close {
                right: 0;
                top: -35px;
            }
        }
    }
    &.fade {
        transition: opacity .35s linear;
    }
    &.show {
        display: block;
        opacity: 1;
        .modal-dialog {
            transform: none;
        }
    }
}

.modal-backdrop {
    display: none;
    background-color: #000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0;
    z-index: 1040;
    opacity: 0;
    transition: opacity .15s linear;
    &.show {
        display: block;
        opacity: 0.5;
    }
}